/*******************************************************************************
API - Constant
*******************************************************************************/
const C_API_VERSION = "4.2.0";

/*******************************************************************************
API - Constant - URI
*******************************************************************************/
// Used as GET parameter to hide content
const C_API_URI_NOHEADER = "noheader";
const C_API_URI_NONAVBAR = "nonavbar";
const C_API_URI_NOFOOTER = "nofooter";
// Used as GET parameter to load a body
const C_API_URI_BODY = "body";

/*******************************************************************************
API - Constant - Cookie
*******************************************************************************/
const C_API_COOKIE_SESSION = "session";

/*******************************************************************************
API - Constant - AJAX
*******************************************************************************/
const C_API_AJAX_SUCCESS = "success";
